import { useEffect, useState } from "react";
import HeadSection from "../../Utilities/HeadSection";
import PrintAndDownload from "../../Utilities/PrintAndDownload";
import { useDispatch, useSelector } from "react-redux";
import { getAllLiquidation, getNewLiqidationForSalesUser } from "../../../redux/slices/liquidation/thunk";
import TableForSalesUser from "./TableForSalesUser";
import LastLiquidationInfo from "./LastLiquidationInfo";


export default function SalesLiuidationComponent() {
    const user = JSON.parse(localStorage.getItem('user'));
    const[isLodaing,setIsLoading]=useState(false);
    const[selectedLiquidation,setSelectedLiquidation]=useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        let query = ``;
        // مندوب
        if (user?.liquidation_user) query += `sales_id=${user?._id}`;
        setIsLoading(true);
        dispatch(getAllLiquidation(query));
        setIsLoading(false);
    }, []);

   

    const liquidation = useSelector(state => state.liquidationReducer.liquidation);

    const latestLiquidation=selectedLiquidation==0 ?
    liquidation?.find(el=> el.status==='new'):selectedLiquidation;

    console.log('latestLiquidation', latestLiquidation);
    console.log('selectedLiquidation',selectedLiquidation);

    return (
        <div className="firstContainer2 mx-2">
            <HeadSection label={"التصفية"} />
            <div className='d-flex  justify-content-end  my-4'>
                <PrintAndDownload />
            </div>
               {latestLiquidation &&<LastLiquidationInfo selectedLiquidation={selectedLiquidation} latestLiquidation={latestLiquidation} isLodaing={isLodaing} />} 

                <TableForSalesUser liquidations={liquidation} setSelectedLiquidation={setSelectedLiquidation} />
            <div>
               
            </div>

        </div>
    )
}
