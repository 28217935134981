export default function Input({
  label,
  formik,
  onChange,
  width,
  minWidth,
  value,
  setValue = false,
  name,
  disabled = false,
  height,
  UserIcon,
  type = "text",
  lableClass,
  minValue = "0",
  placeholder,
  bankPattern = false,
  classnam = null,
  className = null,
  onKeyPress,
  maxLength,
  minLength,
  
}) {
  const number_reg = /^[0-9]*$/; // Allows only English numerals

  return (
    <div
      className={`d-flex flex-column ${className}`}
      style={{ width: width, minWidth: minWidth }}
    >
      <div className={`label ${lableClass}`}>
        {label}
        {UserIcon}
      </div>
      <input
        className={classnam}
        type={ type}
        name={name}
        value={value}
        onKeyPress={onKeyPress}

        onChange={
          setValue
            ? (e) =>
                setValue((prev) =>
                  type == "number"
                    ? number_reg.test(e.target.value)
                      ? e.target.value
                      : ""
                    : e.target.value
                )
            : onChange
        }
        placeholder={`${placeholder ? placeholder : label}`}
        readOnly={disabled}
        style={{ height: height, fontFamily: "Cairo" }}
        min={minValue}
        maxLength={maxLength}
        minLength={minLength}
      />
    </div>
  );
}
