import { useLocation } from "react-router-dom";
import HeadSection from "../../Utilities/HeadSection";
import PrintAndDownload from "../../Utilities/PrintAndDownload";
import Box1Component from "./Box1Component";
import Box2Component from "./Box2Component";
import Box3Component from "./Box3Component";

export default function EditLiquidationComponent() {
  const location = useLocation();
  const oneLiquidation = location.state;
  console.log('EditLiquidationComponent',location.state);

  return (
    <div className="firstContainer mx-2">
      <HeadSection label={"استعراض التصفية"} />
      <div className='d-flex  justify-content-end  my-4'>
        <PrintAndDownload />
      </div>

      <div className='permissionsLayout w-auto px-4'>
        <div className="d-flex gap-5 mt-2">
          <div className="liquidation-details-p"> تصفية رقم ({oneLiquidation?.liquidation_number}) </div>
          {/* <div className="liquidation-details-p"> الموظف </div> */}
          {/* <div className="liquidation-details-p"> بشار العجاج </div> */}
        </div>

        <hr className="" style={{ color: 'rgba(217, 217, 217, 1)', border: '2px solid' }} />

        <Box1Component item={oneLiquidation} />

        <hr className="" style={{ color: 'rgba(217, 217, 217, 1)', border: '2px solid' }} />
        
        <Box2Component item={oneLiquidation} />

        <Box3Component item={oneLiquidation} />
      

      </div>
    </div>
  )
}
