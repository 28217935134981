import { useDispatch, useSelector } from "react-redux";
// import GetAllMembersHook from "../../hook/members/GetAllMembersHook";
import HeadSection from "../Utilities/HeadSection";
import SearchComponent from "./SearchComponent";
import TableMembersComponent from "./TableMembersComponent";
import { useEffect, useState } from "react";
import {
  getAllMembers,
  getAllMembersWithPagination,
} from "../../redux/slices/memberShips/thunk";
import {
  getAllUsers,
  getLiquidationUsers,
  getOneUser,
} from "../../redux/slices/users/thunk";
import { useSearchParams } from "react-router-dom";
import Pagination from "../Utilities/Pagination";
import CheckForPermission from "../Utilities/CheckForPermission";
import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";
import { Spinner as BootstrapSpinner } from "react-bootstrap"; // Import Bootstrap Spinner

export default function AllMembersComponent() {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [searchType, setSearchType] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [sendFrom, setSendFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //
  const [loading, setloading] = useState(true);

  const [salesID, setSalesID] = useState("");
  const [salesSubervisersID, setSalesSubervisersID] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const get_all = async () => {
      // if(pageCount >0) return;
      let page = 1;
      if (searchParams.get("page") == null || searchParams.get("page") == 0) {
        console.log("pppppppppppppppppp");
        searchParams.set("page", 1);
        setSearchParams(searchParams);
      } else {
        console.log("pppppppppppppppp000000", page);
        page = searchParams.get("page");
        if (searchParams.get("page") == 0) page = 1;

        searchParams.set("page", page);
        setSearchParams(searchParams);
      }

      if (page == 0) page = 1;
      let query = `page=${page}`;
      // let query=``;
      // صفحة المندوب
      if (user?.liquidation_user || user?.userMarketer)
        query += `&user=${user?._id}`;
      // // صفحة المسوق
      // if(user?.userMarketer) query+=`user=${user?.father_id}`;

      console.log("query..........", query);

      // if(page==0) return;
      setloading(true);
      await dispatch(getAllMembersWithPagination(query));
      setloading(false);

      if (user?.isAdmin) dispatch(getLiquidationUsers());
      else dispatch(getOneUser({ id: user?._id }));

      let queryUsers = ``;
      if (user && !user.isAdmin) {
        queryUsers = `?father_id=${user._id}`;
        //محاسب
        // if(!user.liquidation_user && !user.isAdmin) queryUsers=``;
      }
      // مسوق
      // if(user?.userMarketer&&!user.liquidation_user && !user.isAdmin){
      //   queryUsers=`&father_id=${user.father_id}`;
      // }
      console.log("queryUsers", queryUsers);

      await dispatch(getAllUsers(queryUsers));
    };
    get_all();
  }, []);

  const members = useSelector(
    (state) => state.membersReducer.allMembersWithPagination
  );

  const nextPage = async (page) => {
    let next_page = page;
    if (page == 0) return;
    if (searchParams.get("page") == null) {
      //console.log('pppppppppppppppppp')
      searchParams.set("page", 1);
      setSearchParams(searchParams);
    } else {
      page = searchParams.get("page");
      searchParams.set("page", next_page);
      setSearchParams(searchParams);
    }
    const salesIDFromURL = searchParams.get("user");
    const startDateFromURL = searchParams.get("start_date");
    const endDateFromURL = searchParams.get("end_date");
    const statusFromURL = searchParams.get("membership_status");
    const sendFromFromURL = searchParams.get("sent_by");
    const searchTypeFromURL = searchParams.get(`&${searchType}`);

    let query = `page=${next_page}`;
    if (salesIDFromURL) {
      query += `&user=${salesIDFromURL}`;
    }
    if (startDateFromURL) query += `&start_date=${startDateFromURL}`;
    if (endDateFromURL) query += `&end_date=${endDateFromURL}`;
    if (statusFromURL) query += `&membership_status=${statusFromURL}`;
    if (sendFromFromURL) query += `&sent_by=${sendFromFromURL}`;
    if (searchTypeFromURL) query += `&${searchType}=${searchTypeFromURL}`;

    if (user?.liquidation_user || user?.userMarketer)
      query += `&user=${user?._id}`;
    // // صفحة المسوق
    // if(user?.userMarketer) query+=`user=${user?.father_id}`;

    // let query = ``;
    // مندوب
    // if (user?.liquidation_user || user?.userMarketer) query += `user=${user?._id}`;
    // مسوق
    // if (user?.userMarketer) query += `user=${user?.father_id}`;
    // if (sendFrom != "") query += `&sent_by=${sendFrom.trim()}`;
    // if (status != "") query += `&membership_status=${status}`;
    // if (startDate != "") query += `&start_date=${startDate}&end_date=${endDate}`;

    // if (searchType != "0") query += `&${searchType}=${searchWord}`;
    searchParams.set("page", next_page);
    setSearchParams(searchParams);
    // await dispatch(getAllMembersWithPagination(query));

    await dispatch(getAllMembersWithPagination(query));
  };

  const screenID = "665f0dee70673dba321d15f3";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let hasEdit = false;
  // undefined
  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, "edit");
  } else {
    hasEdit = true;
  }

  console.log("members", members);

  return (
    <div>
      <HeadSection label={"العضويات"} />
      <div className="permissionsLayout mt-3">
        <SearchComponent
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          searchType={searchType}
          setSearchType={setSearchType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          sendFrom={sendFrom}
          setSendFrom={setSendFrom}
          status={status}
          setStatus={setStatus}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          salesID={salesID}
          setSalesID={setSalesID}
          salesSubervisersID={salesSubervisersID}
          setSalesSubervisersID={setSalesSubervisersID}
          members={members}
        />
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <BootstrapSpinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </BootstrapSpinner>
          </div>
        ) : (
          <TableMembersComponent members={members} hasEdit={hasEdit} />
        )}{" "}
      </div>

      <div>
        <Pagination onPress={nextPage} pageCount={members?.num_of_pages} />
      </div>
    </div>
  );
}
