import React, { useState, useEffect } from 'react';
import styles from './SalesReport.module.css';
import { getReport } from '../../../redux/slices/memberShips/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoArrowBackOutline } from "react-icons/io5";
import logo2 from "../../../Images/logo2.png";
import { getOneUser} from "../../../redux/slices/users/thunk";
import PrintAndDownload from "../../Utilities/PrintAndDownload";
import html2pdf from 'html2pdf.js';





export default function SalesReport() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams,setSearchParams]=useSearchParams();
    // const [salesID, setSalesID] = useState(0);
    const [selectedUser, setSelectedUser] = useState();
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
    console.log(formattedDate);
        
    const salesReport = useSelector(state => state.membersReducer.getReport);
    console.log("salesReport",salesReport)
console.log("searchParams.get('endDate')",searchParams.get('start_date'))

    let users = useSelector(state => state.usersReducer.users);
        users = users?.filter(el => el.liquidation_user && !el.isAdmin)
     let salesID2 = searchParams.get('salesID');
     let salesSubervisersID = searchParams.get('salesSubervisersID');
console.log("salesSubervisersID",salesSubervisersID)
        
      
console.log("salesID2",salesID2)
    useEffect(() => {
        if (loggedUser) {
            let query = `user_id=${loggedUser._id}`;
            let startDate = searchParams.get('start_date');
            let endDate = searchParams.get('end_date');
            let sendFrom = searchParams.get('sent_by');
            let name = searchParams.get('name');
            let membership_number = searchParams.get('membership_number');
            let price = searchParams.get('price');
            if((loggedUser?.isAdmin || loggedUser?.is_accountant) && salesID2){
                query = `user_id=${salesID2}`;
            }
            if(salesSubervisersID){
                query = `user_id=${salesSubervisersID}`;

            }
            if (startDate) {
                query += `&startDate=${startDate}`;
            }
            if (sendFrom) {
                query += `&sent_by=${sendFrom}`;
            }
            if (name) {
                query += `&name=${name}`;
            }
            if (membership_number) {
                query += `&membership_number=${membership_number}`;
            }
            if (price) {
                query += `&price=${price}`;
            }

            dispatch(getReport(query));
        }
    }, []);
let chooseID = ""
if(salesID2){
    chooseID =salesID2
}
if(salesSubervisersID){
    chooseID =salesSubervisersID
}
else chooseID = loggedUser?._id

    useEffect(()=>{
        dispatch(getOneUser({id:chooseID}));
      },[]);
      const {oneUser} =useSelector(state=>state.usersReducer);

      console.log("oneUser",oneUser)
      
    const backHandler = () => {
          navigate(`/members`);
        }
        // const handleSearch = async () => {
        //     let query = ``;

        //         const user = users?.find(user => user._id === salesID);
        //         setSelectedUser(user);
        
        //     if (loggedUser?.isAdmin) query = `user_id=${salesID}&`;
        //     else query = `user_id=${loggedUser?._id}&`;
        //     await dispatch(getReport(query));
        //   }
        // const handleCancelSearch = async () => {
        //     let query = ``;
        //     setSelectedUser('')
        //     setSalesID(0);
        
        //     if (loggedUser?.isAdmin) query = `user_id=${loggedUser?._id}&`;
        //     else query = `user_id=${loggedUser?._id}&`;
        //     await dispatch(getReport(query));
        //   }


        //------------------print------------------
        const handlePrint = () => {
            const printWindow = window.open("", "_blank");
            printWindow.document.write("<html><head><title>Print</title>");
            printWindow.document.write("<style>");
            printWindow.document.write(`
                body {
                    font-family: Arial, sans-serif;
                    text-align: center;
                    direction: rtl;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                }
                th, td {
                    border: 1px solid #dddddd;
                    padding: 8px;
                    text-align: center; /* Center the text inside table cells */
                }
                .table-header {
                    background-color: #f2f2f2;
                }
                tr:nth-child(even) {
                    background-color: #f9f9f9;
                }
            `);
            printWindow.document.write("</style>");
            printWindow.document.write("</head><body>");
        
            // Print header table
            printWindow.document.write(`
                <table>
                    <thead>
                        <tr>
                            <th>تاريخ الطباعة</th>
                            <th>اسم المستخدم</th>
                            <th>المدير</th>
                            <th>المشرف</th>
                            <th>من تاريخ</th>
                            <th>إلى تاريخ</th>
                            <th>محدثات إضافية</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>${formattedDate}</td>
                            <td>${salesID2 || salesSubervisersID ? oneUser.fullname : loggedUser?.fullname}</td>
                            <td>مدير</td>
                            <td>${salesID2 || salesSubervisersID ? oneUser.fullname : loggedUser?.fullname}</td>
                            <td>${searchParams.get('start_date') ? searchParams.get('start_date') : 'الجميع'}</td>
                            <td>${searchParams.get('end_date') ? searchParams.get('end_date') : 'الجميع'}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            `);
        
            // Print sales report tables (if available)
            salesReport && salesReport.slice(1).forEach((item, index) => {
                printWindow.document.write(`
                    <table>
                        <thead>
                            <tr>
                                <th>نوع العرض</th>
                                <th>عدد العضويات المستلمة</th>
                                <th>حساب اليوزر للشركة</th>
                                <th>الاجمالي المستحق علي اليوزر</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${item.offer_type}</td>
                                <td>${item.card_received}</td>
                                <td>${item.user_account}</td>
                                <td>${item.money_for_company}</td>
                            </tr>
                        </tbody>
                    </table>
                `);
            });
        
            // Summary tables
            printWindow.document.write(`
                <table>
                    <thead>
                        <tr>
                            <th>اجمالي البطاقات</th>
                            <th>عدد البطاقات المستلمة</th>
                            <th>عدد البطاقات غير المستلمة</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>${salesReport[0]?.all_cards}</td>
                            <td>${salesReport[0]?.all_card_received}</td>
                            <td>${salesReport[0]?.all_card_un_received}</td>
                        </tr>
                    </tbody>
                </table>
        
                <table>
                    <thead>
                        <tr>
                            <th>اجمالي المستحق علي اليوزر</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>${salesReport[0]?.all_money_for_company}</td>
                        </tr>
                    </tbody>
                </table>
            `);
        
            // End of the document
            printWindow.document.write("</body></html>");
            printWindow.document.close();
            printWindow.print();
        };
           
        //------------------print------------------
        //------------------Download------------------
        const handleDownload = () => {
            // Create a temporary container to hold the HTML content
            const contentDiv = document.createElement('div');
            contentDiv.innerHTML = `
            <html>
            <head>
                <title>Download Report</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        text-align: center;
                        direction: rtl;
                        margin: 20px
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-bottom: 20px;
                    }
                    th, td {
                        border: 1px solid #dddddd;
                        padding: 8px;
                        text-align: center;
                    }
                    .table-header {
                        background-color: #f2f2f2;
                    }
                    tr:nth-child(even) {
                        background-color: #f9f9f9;
                    }
                </style>
            </head>
            <body>
                <table>
                    <thead>
                        <tr>
                            <th>تاريخ الطباعة</th>
                            <th>اسم المستخدم</th>
                            <th>المدير</th>
                            <th>المشرف</th>
                            <th>من تاريخ</th>
                            <th>إلى تاريخ</th>
                            <th>محدثات إضافية</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>${formattedDate}</td>
                            <td>${salesID2 || salesSubervisersID ? oneUser.fullname : loggedUser?.fullname}</td>
                            <td>مدير</td>
                            <td>${salesID2 || salesSubervisersID ? oneUser.fullname : loggedUser?.fullname}</td>
                            <td>${searchParams.get('start_date') ? searchParams.get('start_date') : 'الجميع'}</td>
                            <td>${searchParams.get('end_date') ? searchParams.get('end_date') : 'الجميع'}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
        
                ${salesReport && salesReport.slice(1).map((item) => `
                    <table>
                        <thead>
                            <tr>
                                <th>نوع العرض</th>
                                <th>عدد العضويات المستلمة</th>
                                <th>حساب اليوزر للشركة</th>
                                <th>الاجمالي المستحق علي اليوزر</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>${item.offer_type}</td>
                                <td>${item.card_received}</td>
                                <td>${item.user_account}</td>
                                <td>${item.money_for_company}</td>
                            </tr>
                        </tbody>
                    </table>
                `).join('')}
        
                <table>
                    <thead>
                        <tr>
                            <th>اجمالي البطاقات</th>
                            <th>عدد البطاقات المستلمة</th>
                            <th>عدد البطاقات غير المستلمة</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>${salesReport[0]?.all_cards}</td>
                            <td>${salesReport[0]?.all_card_received}</td>
                            <td>${salesReport[0]?.all_card_un_received}</td>
                        </tr>
                    </tbody>
                </table>
        
                <table>
                    <thead>
                        <tr>
                            <th>اجمالي المستحق علي اليوزر</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>${salesReport[0]?.all_money_for_company}</td>
                        </tr>
                    </tbody>
                </table>
            </body>
            </html>`;
        
            // Use html2pdf to convert the content to PDF and download
            html2pdf()
              .from(contentDiv)
              .set({
                filename: `sales_report_${formattedDate.replace(/\//g, '-')}.pdf`,
                jsPDF: { orientation: 'portrait' },
                html2canvas: { scale: 2 }, // Improve the quality of the generated PDF
              })
              .save();
        };
        
        
        
        
        //------------------Download------------------
   

    return (
        <div className={styles.tablediv} style={{overflow:"auto"}}>
          <div onClick={backHandler} className={styles.backicon}>
               <span> الرجوع الي صفحة العضويات </span> 
            <IoArrowBackOutline />
            </div>
            <div>
            <img src={logo2} alt="logo" />
            </div>
            {/* {
            loggedUser?.isAdmin &&
            <div style={{display:"flex", alignItems:"flex-end", gap:"20px"}}>
            <div className={styles.userName}>
              <label className={styles.UserNameLabel}>اسم المستخدم</label>
              <select
                className={styles.userNameSelect}
                value={salesID}
                onChange={(e) => setSalesID(e.target.value)}
              >
                <option value={'0'}>اختر المستخدم </option>
                {
                  users?.map((el, i) =>
                    <option key={i} value={el?._id}> {el?.fullname} </option>
                  )
                }
              </select>
            </div>
              <div className={styles.filteration} onClick={() => handleSearch()} >فلترة</div>
              <div className={styles.cancelfilteration} onClick={() => handleCancelSearch()} >إلغاء الفلترة</div>
              </div>
          } */}
<div style={{ display: 'flex', justifyContent: 'flex-end', width: '90%' }}>
            <PrintAndDownload
            onPrint={handlePrint}
            onDownload={handleDownload}
            />
</div>
           

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th rowSpan="2">تاريخ الطباعة</th>
                        <th rowSpan="2">اسم المستخدم</th>
                        <th rowSpan="2">المدير</th>
                        <th rowSpan="2">المشرف</th>
                        <th colSpan="3">التفاصيل</th>
                    </tr>
                    <tr>
                        <th>من تاريخ</th>
                        <th>إلى تاريخ</th>
                        <th>محدثات إضافية</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{formattedDate}</td>
                        <td>{salesSubervisersID || salesID2 ? oneUser.fullname : loggedUser?.fullname}</td>
                        <td>مدير</td>
                        <td>{salesSubervisersID || salesID2 ? oneUser.fullname : loggedUser?.fullname}</td>
                        <td>{searchParams.get('start_date') ? searchParams.get('start_date') : 'الجميع'}</td>
                        <td>{searchParams.get('end_date') ? searchParams.get('end_date') : 'الجميع'}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            {salesReport && salesReport.slice(1).map((item, index) => (
                <table key={index} className={styles.table1}>
                    <thead>
                        <tr>
                            <th>نوع العرض</th>
                            <th>عدد العضويات المستلمة</th>
                            <th>حساب اليوزر للشركة</th>
                            <th>الاجمالي المستحق علي اليوزر</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{item.offer_type}</td>
                            <td>{item.card_received}</td>
                            <td>{item.user_account}</td>
                            <td>{item.money_for_company}</td>
                        </tr>
                    </tbody>
                </table>
            ))}

            <table className={styles.table2}>
                <thead>
                    <tr>
                        <th>اجمالي البطاقات</th>
                        <th>عدد البطاقات المستلمة</th>
                        <th>عدد البطاقات غير المستلمة</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{salesReport[0]?.all_cards}</td>
                        <td>{salesReport[0]?.all_card_received}</td>
                        <td>{salesReport[0]?.all_card_un_received}</td>
                    </tr>
                </tbody>
            </table>
 
            <table className={styles.table3}>
                <thead>
                    <tr>
                        <th>اجمالي المستحق علي اليوزر</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{salesReport[0]?.all_money_for_company}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
