import HeadSection from "../../Utilities/HeadSection";
import Input from "../../Utilities/Input";
import PrintAndDownload from "../../Utilities/PrintAndDownload";
import { useState } from "react";
import EyeButton from "../../Utilities/EyeButton";
import Select from "../../Utilities/Select";
import MultiSelect from "../../Utilities/MultiSelect";
import EditProfileHook from "../../../hook/setting/EditProfileHook";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Spinner } from "react-bootstrap";





export default function PersonalProfileComponent() {
  const [oneUser, formik, isLoading, permissionGroups, onSubmit, isSubmitLoading] = EditProfileHook();
  const [type, setType] = useState('password');

  console.log("isSubmitLoading",isSubmitLoading)

  if (isLoading) return;
  let account_type = '';
  if (oneUser?.isAdmin) account_type = 'مدير نظام';
  if (oneUser?.is_accountant) account_type = 'محاسب';
  if (oneUser?.liquidation_user) account_type = 'مدير مبيعات';
  if (oneUser?.userMarketer) account_type = 'مشرف';
  console.log("oneUser",oneUser)

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
  
    printWindow.document.write(`
      <html>
        <head>
          <title>طباعة الملف الشخصي</title>
          <style>
            body {
              direction: rtl;
              font-family: 'Arial', sans-serif;
            }
            h1, h2 {
              text-align: center;
            }
            .container {
              margin: 20px;
            }
            .inputLabel {
              margin-top: 20px;
              font-weight: bold;
            }
            .inputValue {
              margin-bottom: 10px;
              border: 1px solid #ccc;
              padding: 8px;
              border-radius: 4px;
            }
            .button {
              margin-top: 20px;
              padding: 10px;
              background-color: #4CAF50;
              color: white;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              text-align: center;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>الملف الشخصي</h1>
            <h2>اسم المستخدم: ${oneUser?.fullname || 'غير متوفر'}</h2>
            <div>
              <div class="inputLabel">الاسم الثلاثي:</div>
              <div class="inputValue">${formik.values.fullname || 'غير متوفر'}</div>
              <div class="inputLabel">البريد الالكتروني:</div>
              <div class="inputValue">${formik.values.email || 'غير متوفر'}</div>
              <div class="inputLabel">نوع الحساب:</div>
              <div class="inputValue">${account_type || 'غير متوفر'}</div>
              ${oneUser?.liquidation_user ? `
                <div class="inputLabel">عدد المشرفين:</div>
                <div class="inputValue">${formik.values.number_of_supervisors_allowed || 'غير متوفر'}</div>
                <div class="inputLabel">الحد الائتماني:</div>
                <div class="inputValue">${formik.values.wallet_credit_limit || 'غير متوفر'}</div>
              ` : ''}
              <div class="inputLabel">الصلاحيات:</div>
              <div class="inputValue">${oneUser?.permissions?.map(permission => permission.nameAr).join(', ') || 'غير متوفر'}</div>
            </div>
          </div>
        </body>
      </html>
    `);
  
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  console.log("oneUser",oneUser)
  
  const handleDownload = () => {
    // Create a container with the table to download
    const tableHTML = `
       <html>
        <head>
          <title>طباعة الملف الشخصي</title>
          <style>
            body {
              direction: rtl;
              font-family: 'Arial', sans-serif;
            }
            h1, h2 {
              text-align: center;
            }
            .container {
              margin: 20px;
            }
            .inputLabel {
              margin-top: 20px;
              font-weight: bold;
            }
            .inputValue {
              margin-bottom: 10px;
              border: 1px solid #ccc;
              padding: 8px;
              border-radius: 4px;
            }
            .button {
              margin-top: 20px;
              padding: 10px;
              background-color: #4CAF50;
              color: white;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              text-align: center;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>الملف الشخصي</h1>
            <h2>اسم المستخدم: ${oneUser?.fullname || 'غير متوفر'}</h2>
            <div>
              <div class="inputLabel">الاسم الثلاثي:</div>
              <div class="inputValue">${formik.values.fullname || 'غير متوفر'}</div>
              <div class="inputLabel">البريد الالكتروني:</div>
              <div class="inputValue">${formik.values.email || 'غير متوفر'}</div>
              <div class="inputLabel">نوع الحساب:</div>
              <div class="inputValue">${account_type || 'غير متوفر'}</div>
              ${oneUser?.liquidation_user ? `
                <div class="inputLabel">عدد المشرفين:</div>
                <div class="inputValue">${formik.values.number_of_supervisors_allowed || 'غير متوفر'}</div>
                <div class="inputLabel">الحد الائتماني:</div>
                <div class="inputValue">${formik.values.wallet_credit_limit || 'غير متوفر'}</div>
              ` : ''}
              <div class="inputLabel">الصلاحيات:</div>
              <div class="inputValue">${oneUser?.permissions?.map(permission => permission.nameAr).join(', ') || 'غير متوفر'}</div>
            </div>
          </div>
        </body>
      </html>`

    // Create a temporary DOM element to render the table for canvas conversion
    const container = document.createElement("div");
    container.innerHTML = tableHTML;
    document.body.appendChild(container);

    // Use html2canvas to convert the table to canvas
    html2canvas(container).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const marginLeft = 15; // Left margin
      const marginTop = 20; // Top margin
      const imgWidth = 210 - marginLeft * 2; // A4 width (210mm) minus left and right margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio of the image

      // Add the image to the PDF with margins
      pdf.addImage(imgData, "PNG", marginLeft, marginTop, imgWidth, imgHeight);
      pdf.save("data-summary.pdf"); // Save the PDF as 'data-summary.pdf'

      // Remove the temporary container
      document.body.removeChild(container);
    }).catch((error) => {
      console.error("Error generating download: ", error);
    });
  };
 
  
  
  //oneUser.isAdmin
  return (
    <div className="firstContainer mx-2">
      <HeadSection label={"الملف الشخصي"} />

      <div className="d-flex justify-content-end my-4" >
        <PrintAndDownload onPrint={handlePrint} onDownload={handleDownload}/>
      </div>

      <div className="permissionsLayout">
        <Input
          name={"fullname"}
          label={"الاسم الثلاثي"}
          value={formik.values.fullname} onChange={formik.handleChange}
          width={"100%"}
          classnam={"inputsReduisAndPadding2"}
        />
        <Input
          name={"email"}
          type="email"
          label={"البريد الالكتروني"}
          value={formik.values.email} onChange={formik.handleChange}
          width={"100%"}
          classnam={"inputsReduisAndPadding2"}

        />
        <div className="inputContainerProfile">
          <Input
            name={"password"}
            type={type}
            label={"كلمة المرور"}
            value={formik.values.password} onChange={formik.handleChange}
            width={"97%"}
            classnam={"inputsReduisAndPadding2"}
            />
          <EyeButton type={type} setType={setType} circle={true} />
        </div>

        <div className="d-flex justify-content-between seetingpProfileMobileResponsive">

          <Input
            name={"isAdmin"}
            label={"نوع الحساب"}
            value={account_type}
            disabled={true}
            width={'30%'}
            classnam={"inputsReduisAndPadding2"}

          />
          {
            oneUser?.liquidation_user && <>
              <Input
                name={"number_of_supervisors_allowed"}
                label={"عدد المشرفين"}
                value={formik.values.number_of_supervisors_allowed}
                disabled={true}
                width={'30%'}
                classnam={"inputsReduisAndPadding2"}

              />
              <Input
                name={"wallet_credit_limit"}
                label={"الحد الائتماني"}
                value={formik.values.wallet_credit_limit}
                disabled={true}
                width={'30%'} 
                classnam={"inputsReduisAndPadding2"}

              />
            </>
          }

        </div>
<div classnam="minWidthMultiSelector">
        {/* {permissionGroups && <MultiSelect labelForOption={'nameAr'} label={'الصلاحيات'} selectedOptionsIDS={oneUser?.permissions} options={permissionGroups} width={'100%'} disabled={true}/>} */}
        {permissionGroups && <Input  label={'الصلاحيات'} 
    value={oneUser?.permissions?.map(permission => permission.nameAr).join(', ') || ''}
    classnam={"inputsReduisAndPadding2"} width={'100%'} disabled={true}/>}
</div>

        <div className="d-flex my-4" style={{ gap: '32px' }}>
          {/* <button className="addButton w-50" disabled={isSubmitLoading} onClick={() => onSubmit()}  >  {isSubmitLoading && <Spinner />} تعديل </button> */}
          <button className="addButton w-100" disabled={isSubmitLoading} onClick={() => onSubmit()} > {isSubmitLoading && <Spinner />} حفظ </button>
        </div>

        {/* <MultiSelect disabled={!haveEdit} label={'الصلاحيات'} selectedOptionsIDS={selectedPermissionsIDS} setSelectedOptionsIDS={setSelectedPermissionsIDS} options={permissionGroups} /> */}

        {/* <Select name={'periodType'} label={'نوع الفترة'} value={formik.values.periodType} onChange={formik.handleChange} options={periodTypeOptions} width={'492px'} height={'50px'} /> */}
        {/* <Input formik={formik} name={'offerNameAr'} label={'اسم العرض باللغة العربية'} value={formik.values.offerNameAr} onChange={formik.handleChange} UserIcon={<FaRegAddressCard />} width={'492px'} height={'50px'} /> */}
      </div>
    </div>
  );
}
